import { template as template_1b0087e98dc3419380824003f50a7ecd } from "@ember/template-compiler";
const FKText = template_1b0087e98dc3419380824003f50a7ecd(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
