import { template as template_acdb6004e4f942208713a60a0fbc0f31 } from "@ember/template-compiler";
const SidebarSectionMessage = template_acdb6004e4f942208713a60a0fbc0f31(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
