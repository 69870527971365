import { template as template_de168f3368bc45449ed8b88b7c7a4b2a } from "@ember/template-compiler";
const FKLabel = template_de168f3368bc45449ed8b88b7c7a4b2a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
